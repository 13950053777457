import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Texts from '../Texts';
import Links from '../Links';

const Purposes = ({ title, classes }) => {
  const { dataJson } = useStaticQuery(
    graphql`
      {
        dataJson {
          pages {
            purposes {
              content
              links {
                name
                url
              }
            }
          }
        }
      }
    `
  );
  const { content, links } = dataJson.pages.purposes;

  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      <Texts content={content} />
      <Links title="Links:" links={links} />
    </div>
  );
};

Purposes.defaultProps = {
  title: null,
};

Purposes.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

export default Purposes;
